body {
  overflow-x: hidden;
}
.block {
  position: absolute;
  left: 0;
  top: 0;
}
.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.sliderContainer {
  position: relative;
  text-align: center;
  line-height: 40px;
  background: #f7f9fa;
  color: #45494c;
  border-radius: 2px;
}
.sliderbg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #f7f9fa;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e6e8eb;
}
.sliderContainer_active .slider {
  top: -1px;
  border: 1px solid #1991fa;
}
.sliderContainer_active .sliderMask {
  border-width: 1px 0 1px 1px;
}
.sliderContainer_success .slider {
  top: -1px;
  border: 1px solid #52ccba;
  background-color: #52ccba !important;
}
.sliderContainer_success .sliderMask {
  border: 1px solid #52ccba;
  border-width: 1px 0 1px 1px;
  background-color: #d2f4ef;
}
.sliderContainer_success .sliderIcon:before {
  content: "";
}
.sliderContainer_fail .slider {
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}
.sliderContainer_fail .sliderMask {
  border: 1px solid #f57a7a;
  background-color: #fce1e1;
  border-width: 1px 0 1px 1px;
}
.sliderContainer_fail .sliderIcon:before {
  content: "";
}
.sliderContainer_active .sliderText,
.sliderContainer_success .sliderText,
.sliderContainer_fail .sliderText {
  display: none;
}
.sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991fa;
  background: #d1e9fe;
  border-radius: 2px;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.2s linear;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider:hover {
  background: #1991fa;
}
.slider:hover .sliderIcon {
  background-position: 0 -13px;
}
.sliderText {
  position: relative;
}
.refreshIcon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  z-index: 5;
  transition: color 0.3s linear;
}
.refreshIcon:hover {
  color: #6c757d;
}
