/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ngx-toastr/toastr.css";


.modal-sm {
    max-width: 400px !important;
}

.withdrawl-proceed-popp .modal-dialog {
    max-width: 850px !important;
}

.withdrawl-proceed-popp .modal-content {
    border-radius: 25px;
    padding: 0px 15px;
}

.withdrawl-proceed-popp .modal-header {
    padding-top: 25px;
}

.withdrawl-proceed-popp .modal-header .close {
    color: #F1AC1C;
}

.borderRad .modal-header .close-btn-modal span {
    color: #F1AC1C;
    top: 10px;
    right: 10px;
    position: relative;
}

.borderRad.withdrawl-popup .modal-dialog {
    max-width: 484px;
}

.borderRad.withdrawl-popup .modal-content {
    border: 0 !important;
    border-radius: 30px;
}

body {
    color: #fff !important;
}

a.status-package.text-warning {
    color: red !important;
}

a.d-flex.p-3.border-bottom {
    border-bottom: 1px solid #555555 !important;
}

.Lowbalance-popup .modal-dialog {
    width: 370px;
}

.Lowbalance-popup .modal-content {
    border-radius: 15px;
}

.Lowbalance-popup .close-pop-btn {
    color: rgba(241, 172, 28, 1) !important;
}

.Lowbalance-popup .lowBalanceimag img {
    width: 160px;
    margin-bottom: 20px;
    text-align: center;

}

.Lowbalance-popup .lowBalanceimag {
    width: auto !important;
    width: 100% !important;
    text-align: center;
}

.Lowbalance-popup p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 15px;
    color: #fff;
}

.Lowbalance-popup .butbtn-add-low {
    background: linear-gradient(90deg, #F1AC1C 0%, #FF5C00 100%);
    padding: 10px 17px;
    text-align: center;
    color: #fff !important;
    display: block;
    width: 100%;
    border-radius: 12px;
    margin-top: 0px !important;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none !important;
}

.Lowbalance-popup .butbtn-add-low i {
    margin-right: 5px;
    font-size: 12px;
    font-weight: 100;

}

.ng2-tag-input {
    border: none !important;
}

.my-input .ng2-tag-input .ng2-tags-container {
    width: 100% !important;
    display: block !important;
}

.ng2-tag-input__text-input {
    height: auto !important;
    width: 40% !important;

}

.ng2-tag-input__text-input::placeholder {
    width: 100% !important;
}

.my-input tag {
    top: -6px !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    float: left !important;
}


.Lowbalance-popup .lowBalanceimag {
    width: auto !important;
    width: 100% !important;
    text-align: center;
}

.Lowbalance-popup p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
    color: #fff;
}

.tawk-icon-close:before {

    color: #f1ac1c !important;
}

.otp-input {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: solid 1px #FFC107;
    text-align: center;
    font-size: 32px;
    background: #000;
    color: #fff;
}

.login-left-ui {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 25px;
}

.login-left-ui img {
    width: 280px;
    margin-bottom: 30px;
}

.login-left-ui h2 {
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 29px;
    color: #000;
    text-align: center;
}

.login-left-ui h2 b {
    font-weight: 800;
}

.login-left-ui p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 22px;
}



.qrcodeImage canvas {
    width: 170px !important;
    height: 170px !important;
    margin-top: 15px;
    border-radius: 15px;
}

li.breadcrumb-item a {
    color: #fcfcfc !important;
    text-decoration: none;
}

li.breadcrumb-item.active {
    color: #ffbf56;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #ffffff !important;
}

.main-footer span {
    margin-top: 10px !important;
    display: block;
}

input[type=checkbox] {
    accent-color: #F1AC1C;
}

.btn-danger {
    color: #F1AC1C !important;
    background-color: #F1AC1C00 !important;
    border-color: #F1AC1C !important;
    border: 1px solid #F1AC1C !important;
}

.btn-dark {
    background-color: #d19210 !important;
    color: #fff !important;

}

.checkProfileUpdate-popup .modal-dialog {
    max-width: 866px;
}

.withdrawConfirempopuPopup .modal-dialog {
    max-width: 700px;
}


.login-demo-popup .modal-content {
    border-radius: 15px;
    padding: 18px 0 44px;
    background: #F1AC1C !important;
}

/* .login-demo-popup .modal-dialog{
    max-width: 680px !important ;
} */
.form-check-input:checked {
    background-color: #F1AC1C !important;
    border-color: #F1AC1C !important;
}

.modal-body.congrat-login {
    text-align: center;
}

.login-demo-popup .modal-dialog .congrat-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    flex-direction: column;
}

.login-demo-popup .modal-dialog .congrat-login h3 {
    color: #070707 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    padding: 0 0 25px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 0;
}

.login-demo-popup .modal-dialog .congrat-login h3.hurray b {
    font-size: 24px;
}

.login-demo-popup .modal-header .btn-close .close-pop-btn {
    color: #000 !important;
    background: none !important;
    position: relative;
    top: -20px;
}

.login-demo-popup .btn-close {
    background: none !important;
}

.login-demo-popup .modal-dialog .congrat-login p {
    color: #CECECE !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    margin-bottom: 40px;
}

.login-demo-popup .modal-dialog .congrat-login a.btn.btn-next {
    border: 1px solid #e7a419 !important;
    width: 174px !important;
    font-size: 18px !important;
    font-weight: 600;
    height: 45px;
    line-height: 45px !important;
    padding: 0px 20px !important;
    border-radius: 10px;
}

.login-demo-popup .modal-dialog .congrat-login a.btn.btn-next:hover {
    background: #e7a419 !important;
    border: 1px solid #e7a419 !important;
    color: #fff !important;
}

.info-tooltip .tooltip-inner {
    max-width: 440px;
    text-align: left;
}

.space-follow-button button {
    font-weight: 600 !important;

}

.form-control::placeholder {
    font-size: 13px !important;
    color: #ddd;
}

.verfy-new-popup .login-heading span {
    font-size: 30px;
    color: #F1AC1C;
    font-weight: 900;
}

.verfy-new-popup .loginform {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 0px !important;
}

.verfy-new-popup .login-heading {
    color: #FFC107;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;

}

.verfy-new-popup .Otp-text {
    color: #F44336;
    font-weight: 600;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 40px;
}

.verfy-new-popup .login-button {
    width: 196px;
    margin: auto;
}

.login-button {
    color: #feaf2e;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    letter-spacing: 1px;
    line-height: 2px !important;
    border: 1px solid #feaf2e;
    background: #0000;
    border-radius: 10px;
}

.login-button:hover {
    background: #feaf2e;
    color: #fff;
}

.verfy-new-popup .ft-login {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    text-align: center !important;
}

.verfy-new-popup .check-spam {
    color: #feaf2e;
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 400;
}

.toast-container {
    z-index: 9999999999 !important;
}

.logo-new {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    position: absolute;
    right: 2%;
    top: 2%;
}

.logo-new img {
    width: 30px;
}

.carousel-control-next {
    display: none !important;
}

.carousel-control-prev {
    display: none !important;
}

.pl-4.pt-4.pr-4 {
    margin-bottom: -6px !important;
    height: 65px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-top: 8px !important;
}

.pl-4.pt-4.pr-4 .card-title {
    margin-bottom: 0px !important;
    color: #f1ac1c !important;
}

.business-stage .accordion-body {
    padding: 0px !important;
}

.business-stage .accordion-item.open {
    border-bottom: none !important;
}

.business-stage .accordion-button {
    padding: 12px 15px !important;
    background: #f4f6ff !important;
}

.business-stage .accordion-button:not(.collapsed) {
    background-image: linear-gradient(45deg, #f1ac1c, #a46f01) !important;
    ;
    color: #fff !important;
}

.business-stage .accordion-button:not(.collapsed)::after {
    background-image: url("./assets/img/accodian.svg") !important;
}

.breadcrumb-header.dashboard-header .main-header-message span {
    color: #673AB7 !important;
    font-size: 14px !important;
    font-weight: 500;
}

.breadcrumb-header.dashboard-header .main-header-message a.new.nav-link.bg-light {
    background: #dcdcdc !important;
}

.dashboard-header {
    position: absolute !important;
    z-index: 1 !important;
    left: -80px !important;
    top: 60px !important;
    width: 1140px;
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 30% !important;
    width: 40px !important;
    background: #555555 !important;
    border-radius: 50% !important;
    height: 40px !important;
    opacity: 1 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;

}

.carousel-control-prev {
    left: -25px !important;
}

.carousel-control-next {
    right: -10px !important;
}

.das-slider .carousel-control-prev {
    display: none;
}

.das-slider .carousel-control-next {
    display: none;
}




.carousel-item {
    margin-right: 22px !important;
}

.form-pass {
    border: none;
    background: none;
    padding: 0px;
    width: 90%;
    position: relative;
    top: 0px;
}

:focus-visible {
    outline: 0px !important;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: #fff !important;
    color: fieldtext !important;
}

.carousel {
    position: relative !important;
    height: 200px;

}

.editCamp .carousel {
    height: 300px;
}

carousel {
    width: 100%;
}

.h-100-per .carousel {
    height: 300px;
}

/* effect-1 styles */
.effect.effect-1 {
    transition: all 0.2s linear 0s;
}

.effect.effect-1:before {
    content: "";
    font-family: FontAwesome;
    font-size: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
}

button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}

.effect.effect-1:hover {
    text-indent: -10px;
}

.effect.effect-1:hover:before {
    opacity: 1;
    text-indent: -10px;
}





.error_msg {
    color: #f10808 !important;
    list-style: none;
    margin-top: 5px;
    font-size: 11px !important;
    font-weight: 500;
    font-family: 'Helvetica'
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Youtube Watch */

.ytp-chrome-top,
.ytp-chrome-bottom {
    display: none !important;
}


.refresh-link {
    background: transparent !important;
    color: #F1AC1C !important;
    padding: 0px 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 10px;
    margin-right: 0rem;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid #FFC107;
}

.refresh-link:hover {
    color: #fff !important;
    background: #F1AC1C !important;

}

.btn-close {
    background: transparent url('./assets/img/closecolor.png') center/1em auto no-repeat !important;
    opacity: 9 !important;
}

iframe#telegram-login-coinbuck_test_bot {
    position: absolute;
    z-index: 1;
    width: 50px !important;
    background: #54a9eb;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
}

iframe#telegram-login-coinbuck_test_bot i.tgme_widget_login_button_icon {
    top: 3px !important;
    position: relative !important;
}

iframe#telegram-login-coinbuck_test_bot .tgme_widget_login button.tgme_widget_login_button .tgme_widget_login_button_icon {

    top: 3px !important;
    position: relative !important;
}

iframe#telegram-login-coinbuck_test_bot::before {
    content: '>';
}


iframe#telegram-login-coinbuck_test_bot {
    width: 50px !important;
    height: 50px !important;
    left: 0px !important;
    top: -1px !important;
    opacity: 0.01;
    cursor: pointer;
    /* background: #272222 !important; */
    color: #fff !important;
}

iframe#telegram-login-coinbuck_test_bot i.tgme_widget_login_button_icon {
    position: relative !important;
    top: 4px !important;
}


#telegram-login-coinbuck_test_bot .tgme_widget_login button.tgme_widget_login_button .tgme_widget_login_button_icon {

    position: relative !important;
    top: 4px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.view-more {
    color: #5152e2;
    cursor: pointer;
    margin: 9px 0px;
    float: left;
    text-align: right;
    width: 100%;
    font-weight: 500;
    margin-bottom: 0;
}

.bg-gradiants {
    background: linear-gradient(45deg, #f1ac1c, #a46f01) !important;
    ;
    background: -webkit-linear-gradient(45deg, #f1ac1c, #a46f01) !important;
    ;

    color: #fff !important;
}

.modal {
    z-index: 999999 !important;
}

.table thead th {
    background: #c7397d00 !important;
    color: #fff !important;
}

.desctption-popup .modal-body {
    overflow: hidden;
    overflow-y: auto;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
}

.desctption-popup .modal-body p {
    text-align: left;

}

.desctption-popup .modal-body p {
    word-wrap: break-word;
}

app-youtube-watch {

    display: flex;
    justify-content: center;
    /* pointer-events:none; */

}

app-youtube-watch .player-inner-div {
    overflow: hidden;
    display: block;
    position: absolute;
    height: 100%;
    width: 98%;
    height: 100%;
    margin-top: 10px;
}

.modal-header h4 {
    font-size: 15px;
    color: #fff;
}

.description-popup .modal-body {
    max-height: 350px;
    overflow: auto;

}

.cursor-pointer {
    cursor: pointer !important;
}

.create-new-fau {
    padding: 0px 20px;
}

.withdrawl-popup .modal-dialog {
    max-width: 400px;
}

.withdrawl-popup .modal-body {
    padding: 12px 20px;
}

/* .withdrawl-popup .modal-content {
    background: rgba(241, 241, 241, 0.9);
} */
.withdrawl-popup .modal-body .create-new-fau .form-group label {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
}

.withdrawl-popup .modal-body .create-new-fau .form-group .form-control {
    background: #EBEBEB !important;
    box-shadow: inset 12px 13px 10px -13px rgba(0, 0, 0, 0.43) !important;
    border-radius: 8px !important;
    border: none !important;
    height: 40px;
    line-height: 40px !important;
}

.withdrawl-popup .modal-body .create-new-fau .form-group p {
    font-weight: 500;
    font-size: 8px;
    color: #feaf2e;
    opacity: 0.8;
}

input[type="radio"] {
    accent-color: #feaf2e !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 13px !important;
}

.ng-dropdown-panel.ng-select {
    z-index: 999 !important;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #000000de;
    padding: 8px 10px;
}

.ng-select {
    z-index: 1 !important;

}

.ng-select .ng-select-container {
    height: 36px;
    border-radius: 4px;
}

.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;
}

.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
    background: #fff;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 10px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 5px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px;
}

.btn.button-margin,
.butbtn {
    padding: 0px 15px !important;
    color: #f1ac1c;
    border-radius: 10px;
    border: 1px solid #f1ac1c;
    display: block;
    height: 40px;
    line-height: 40px;
    float: left;
    text-align: center;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 1px;
    background: #0000 !important;
}

.btn.button-margin:hover,
.butbtn:hover {
    color: #fff !important;
    border: 1px solid transparent !important;
    background: #f1ac1c !important;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn.btn-danger.btn-padding {
    font-size: 14px !important;
    font-weight: 600;
    background: transparent;
    border-radius: 8px;
    color: #feaf2e !important;
}

.btn.btn-danger.btn-padding:hover {
    background: #f1ac1c !important;
    color: #fff !important;
    border: 1px solid transparent;
}

.progressbar {
    width: 100%;
    background-color: #8a8a8a;
    height: 1rem;
    border-radius: 10px;
    color: #fff !important;
}

select {
    cursor: pointer !important;
}

select option {
    cursor: pointer !important;
}

.progressbar span {
    display: block;
    font-size: 11px;
    color: #fff;
    padding: 0 4px;
    text-align: end;
    position: absolute;
}

.modal-body.banner-view-popup {
    max-height: 350px;
    overflow: auto;
}

html.import-scroll {
    overflow: hidden;
}

.checkProfileUpdate-popup .modal-body {
    padding: 42px 20px 42px 42px;
    padding-bottom: 20px;
}

.checkProfileUpdate-popup .modal-header {
    position: absolute;
    right: 0
}

.checkProfileUpdate-popup .modal-content .close {
    font-size: 25px;
    color: #f1ac1c;
    font-weight: 400;
    z-index: 9;
}

.mob-dis {
    display: none;
}

.close-pop-btn {
    cursor: pointer !important;
    font-size: 28px;
    color: #fff !important;
    font-weight: 400;
    line-height: 100%;
    z-index: 9;
    text-transform: lowercase;
    font-family: 'Poppins';
}

.modal-header .close {
    font-weight: 400;
    font-size: 28px;
}


.getHelp .modal-body p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

.getHelp .modal-header .modal-title {
    margin-bottom: 0;
    text-align: center;
    color: #f1ac1c;
    font-size: 24px;
}

.getHelp .modal-header {
    justify-content: center;
}

.destop_none span.horizontalMenu-click {
    width: 100%;
    height: 40px;
    position: absolute;
}

.g-recaptcha {
    margin-bottom: 1rem;
}

#sliderCap {
    height: 215px !important;
}

button.btn.btn-padding.btn-white {
    color: #fff;
}

a.info-icon i.fa-info {
    cursor: pointer;
}

.customSelect.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    background: none;
}

.customSelect.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #fff !important;
}

.customSelect.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.customSelect.ng-select .ng-select-container {
    width: 220px;
    height: 46px;
    border-radius: 12px;
    background: #1b1b1b;
    color: #fff;
}

.customSelect .ng-dropdown-panel-items .ng-option {
    background: transparent;
    border: 0;
    border-bottom: 0.5px solid #424242;
    color: #fff;
    font-size: 14px !important;
    line-height: 16px;
}

.customSelect .ng-dropdown-panel-items .ng-option:last-child {
    border: 0;
}

.customSelect .ng-dropdown-panel-items.scroll-host {
    background: #202020;
    border-radius: 12px;
    margin-top: 8px;
}

.ng-select .ng-arrow-wrapper {
    background-image: url('assets/img/downArrow.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: 10% 50%;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border: 0;
}

.walletPegination a.page-link {
    margin: 0 5px;
    border-radius: 8px !important;
    background: #D9D9D9;
    padding: 4px 10px;
    height: 28px;
    line-height: 20px;
    font-size: 12px;
    color: #333333 !important;
}

.walletPegination .page-item.disabled .page-link {
    background: #D9D9D9;
    border: 0;
}

.walletPegination .page-link i.fa {
    color: #000;
    font-weight: 700;
    line-height: 20px;
}

.walletPegination .pagination {
    margin-right: 0 !important;
}

.bootsPopup .modal-content {
    background-image: url(assets/img/new-dashboard/booots-bg.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 60% 85% !important;
    border-color: #c77612 !important;
}

.bootsPopup .modal-header {

    height: 0;
}

@media only screen and (min-width: 1600px) {
    .bootsPopup .close-pop-btn img {
        width: 20px;
    }
}

@media only screen and (min-width: 1900px) {
    .login-demo-popup .modal-dialog .congrat-login a.btn.btn-next {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 1900px) and (max-width: 2560px) {

    .dashboard-header {
        left: -16% !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .login-demo-popup .modal-dialog .congrat-login a.btn.btn-next {
        width: 130px !important;
        font-size: 12px !important;
        height: 40px;
        line-height: 39px !important;
        padding: 0px 10px !important;
    }

    .login-demo-popup .modal-dialog {
        max-width: 620px !important;
    }

    .login-demo-popup .modal-dialog .congrat-login h3 {
        font-size: 16px !important;
        padding-left: 10px;
        padding-right: 10px;
    }

    .login-demo-popup .modal-dialog .congrat-login p {
        font-size: 10px !important;
    }

    .d-block.pl-4.pt-4.pr-4 {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .popup-ima img {
        height: auto !important;
    }

    .popupHt {
        padding: 0px 15px !important;
    }

}

@media only screen and (min-width: 300px) and (max-width: 767px) {
    .youtube-popup .modal-body {
        padding: 0 !important;
        max-height: 290px !important;
        overflow-y: scroll;
    }

    .my-input tag {
        margin-bottom: 6px !important;
    }

    .margin-left.my-input .form-control {
        height: 100px !important;
        overflow-y: scroll !important;
    }

    .ng2-tag-input__text-input {
        height: auto !important;
        width: 97% !important;
    }

    .Lowbalance-popup .modal-dialog {
        width: auto;
    }

    .bootsPopup .modal-content {
        border: 0 !important;
        background-position: 95% 85% !important;
        border-radius: 22px 22px 0 0;
    }

    .walletPegination .pagination {
        justify-content: center;
    }

    .customSelect.ng-select .ng-select-container {
        height: 38px;
        border-radius: 8px;
    }

    .mob-tooltip {
        visibility: hidden;
    }

    .getHelp .modal-header .modal-title {
        font-size: 16px;
    }

    .butbtn {
        font-size: 12px !important;
    }

    .withdrawl-claim .modal-dialog .modal-header {
        padding: 30px 20px !important;
    }

    .withdrawl-claim h4 {
        font-size: 14px !important;
    }

    iframe#telegram-login-coinbuck_test_bot {
        width: 110px !important;
        height: 35px !important;
        left: 75px !important;
        top: 45px !important;
        opacity: 0.01;
        cursor: pointer;
        color: #fff !important;
    }

    .logo-new img {
        width: 38px;
    }

    .carousel {
        height: 100%;
    }

    .login-left-ui p {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        line-height: 16px;
    }

    .login-left-ui h2 {
        font-size: 16px;
    }

    .login-left-ui img {
        width: 245px;
        margin-bottom: 30px;
    }

    .login-left-ui {

        padding: 0px 20px;
    }

    /* .logo-new {
     display: none;
    } */
    .login-demo-popup .modal-dialog .congrat-login a.btn.btn-next {

        width: 140px !important;
        font-size: 14px !important;
        padding: 0px 15px !important;
    }

    .checkProfileUpdate-popup .modal-body {
        padding: 26px 0 26px 16px;
    }

    .mob-dis {
        display: block;
    }

    .mob-hid {
        display: none;
    }

    .login-demo-popup .modal-dialog .congrat-login {
        height: auto;
        padding: 0px 20px;
    }

    .login-demo-popup .modal-dialog .congrat-login h3 {
        padding: 10px 0px;
        /* font-size: 25px !important; */
    }

    .login-demo-popup .modal-dialog .congrat-login img {
        width: 160px;
        margin-bottom: 15px;
    }

    .login-demo-popup .modal-dialog .congrat-login a.btn.btn-next {
        margin-bottom: 20px;
    }

    .login-demo-popup .modal-dialog .congrat-login p {
        font-size: 14px !important;
        margin-bottom: 20px;
    }

    .description_pop_up {

        max-height: 350px;
        overflow: hidden;
        overflow-y: auto;
        border-bottom: 3px dotted #FFC107;
        margin-bottom: 10px;
    }

    h6.detals-button {
        margin-bottom: 0;
    }

    .otp-input {
        width: 30px !important;
        font-size: 10px !important;
        height: 30px !important;
        margin: 0px 5px !important;
        padding: 0px !important;
    }

    .verfy-new-popup .ft-login {
        font-size: 10px !important;
        text-align: center !important;
        margin-bottom: 10px !important;
    }

    .verfy-new-popup .Otp-text {
        margin-bottom: 30px;
    }

    .verfy-new-popup .loginform {
        width: auto;
        padding: 0;
    }

    .verfy-new-popup .login-heading {
        font-size: 13px;

        text-align: center;
    }

    .modal-header {
        height: auto;
        line-height: 40px;
    }

    .popupHt {
        border-bottom: 0px solid #cd3979;
        margin-bottom: 10px;
        height: auto !important;
        margin-top: 15px;
    }

    .pl-4.pt-4.pr-4 {
        padding: 0px 10px !important;
    }

    .pl-4.pt-4.pr-4 .card-title {
        font-size: 14px !important;
    }

    .carousel-item {
        margin-right: 0px !important;
    }

    .dashboard-header {
        position: inherit !important;
    }

    .breadcrumb-header.dashboard-header .main-header-message span {
        color: #ffffff !important;
    }

    .withdrawl-popup .modal-body .create-new-fau .form-group p {
        font-size: 8px !important;
    }

    .carousel-control-prev {
        left: -10px !important;
    }

    .view-more {
        text-align: center !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .desctption-popup .modal-body {
        overflow: hidden;
        overflow-y: auto;
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
    }

    .desctption-popup .modal-body p {
        font-size: 10px !important;
    }

    .font-16 {
        font-size: 12px !important;
    }

    .main-content-label,
    .card-table-two .card-title,
    .card-dashboard-eight .card-title {
        margin-bottom: 15px !important;

    }

    .modal-header {
        height: auto;
        padding: 22px 25px 0 !important;
    }

    app-youtube-watch .player-inner-div {
        height: 95%;
    }

}

textarea {
    resize: none !important;
}


.withdrawl-claim h4 {
    font-size: 16px;
    display: inline-block;
    font-family: Helvetica;
}

.withdrawl-claim .modal-dialog {
    max-width: 650px;
}

.withdrawl-claim .modal-content {
    border-radius: 25px;
}

.withdrawl-claim .modal-content .close {
    color: #d19210 !important;
}

.modal-body .error_msg {
    position: absolute;
    line-height: 9px;
}

.mendatoryTask .carousel {
    height: 100%;
}

.mendatoryTask .carousel-indicators {
    display: none !important;
}

.mendatoryTask .carousel-control-next,
.mendatoryTask .carousel-control-prev {
    display: flex !important;
    background: transparent !important;
    top: -58px !important;
}

.mendatoryTask .carousel-control-prev {
    left: initial !important;
    right: 55px;
}

.mendatoryTask .carousel-control-next {
    right: 20px !important;
}

.mendatoryTask .carousel-control-next-icon {
    background-image: url('assets/img/new-dashboard/ArrowRight.png');
}

.mendatoryTask .carousel-control-prev-icon {
    background-image: url('assets/img/new-dashboard/ArrowLeft.png');
}

.youtube-popup .modal-header {
    padding: 0 !important;
    height: auto;
    margin-bottom: 14px;
}

.youtube-popup .modal-body {
    padding: 0 !important;
}

.youtube-popup .modal-header .modal-title {
    font-weight: 600;
    font-family: Montserrat, sans-serif;
}

.youtube-popup .modal-footer button {
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    border-radius: 6px;
    background: linear-gradient(90deg, #F1AC1C 0%, #F16E1D 100%);
    padding: 6px 15px;
    border: 0;

}

.youtube-popup .modal-content {
    padding: 12px 20px;
}

.youtube-popup iframe {
    width: 100%;
    border-radius: 10px;
}

.modal-open .modal.youtube-popup {
    background: rgba(255, 255, 255, 0.20);
}

.modal-open .modal {
    background: #ffffff42;
}

@media only screen and (max-width: 600px) {

    .youtube-popup .modal-dialog-centered {
        align-items: flex-end;
    }

    .youtube-popup .modal-content {
        padding: 16px 20px;
        border-radius: 22px 22px 0px 0px;
        border: 0 !important;
    }

    .youtube-popup .modal-header .modal-title {
        font-size: 14px;
    }

    .youtube-popup .modal-footer button {
        margin: auto;
    }

    .youtube-popup .modal-header img {
        vertical-align: super;
    }
}
@media (max-width: 576px) {
	.modal-header {
		padding: 15px 20px;
	}
	.modal-header .modal-title.fs-5{font-size: 15px !important;}
    .modal-header .modal-title {
        margin-bottom: 0;
        font-size: 15px !important;
    }
    .withdrawl-popup .modal-body {
        padding: 0px 21px;
    }
}
.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none !important;
}



@media only screen and (max-width:600px) {
	.clickVicdeoPopup .modal-content {
		margin-bottom: 71px ;
	}
}