/* Large Version */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.card-title{
		font-size: 11px !important;
	}
}

@media only screen and (max-width: 812px) {}

@media only screen and (min-width:320px) and (max-width: 767px) {
	.page-signin-style {
		padding-top: 30px;
	}

	.p-5 {
		padding: 1rem !important;
	}
	.main-signin-footer a {

		color: #F1AC1C;
	}

	.main-header-message span {
		font-size: 14px !important;
	}

	.latest-tasks .tasks {
		display: block;
		margin: 0px;
	}

	.badge {

		margin: 0rem 0rem 0.5rem 1.3rem;
	}
	.main-signin-footer p {
		color: #a5a0b1;
		font-size: 12px;
	}
	.main-card-signin {
		height: 100%;
		display: flex !important;
		flex-direction: column !important;
		overflow: auto;
	}
	.authentication-pages { 
		height: auto;
		margin-bottom: 12px !important;
		padding: 18px 0;
	}
}



